import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

function TcpaOnYesLegalConsult({ tcpaText, isSecondaryTcpa }) {
	const { t } = useTranslation();

	if (tcpaText) {
		return <span dangerouslySetInnerHTML={{ __html: tcpaText }} />
	}

	if (isSecondaryTcpa) {
		return (
			<Trans
				i18nKey={"MYACCIDENT_ORG_IS_NOT_A_LAW_FIRM_SECONDARY"}
				components={{
					link: <LinkToPartnerAttorneys text={t("MARKETING_PARTNERS")} />
				}}
			/>
		)
	}

	return t("MYACCIDENT_ORG_IS_NOT_A_LAW_FIRM")
}

export default function ImportantMessage({
	isSecondaryTcpa = false,
	legalConsult,
	setLegalConsult,
	setConfirm,
	next,
	findMy,
	injured,
	setInjured,
	hasLawyer,
	setHasLawyer,
	tcpaText,
}) {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();
	return (
		<div
			style={{ maxWidth: 800, margin: "auto", position: "relative" }}
			className="important-message-screen"
		>
			<div
				style={{
					position: "absolute",
					backgroundColor: "#FFF",
					border: "#CCC 1px solid",
					borderRadius: 5,
					padding: "20px 20px 0",
					marginLeft: 180,
					boxShadow: "rgb(0 0 0 / 10%) 0px 10px 20px",
					transition: "all 0.3s",
					opacity: show ? 1 : 0,
					pointerEvents: "none"
				}}
			>
				{t("BASED_ON_THE_FOLLOWING")}:
				<div className="orange-check-list">
					<div>
						<img src="/assets/checked.png" />
						{t("ACCIDENT_SEVERITY")}
					</div>
					<div>
						<img src="/assets/checked.png" />
						{t("DATE_OF_ACCIDENT_WITHIN_STATUTE_OF_LIMITATION")}
					</div>
					<div>
						<img src="/assets/checked.png" />
						{t("THE_OTHER_DRIVER_WAS_AT_FAULT")}
					</div>
				</div>
			</div>

			<div
				onMouseOver={() => setShow(true)}
				onMouseOut={() => setShow(false)}
				className="why hide-mobile"
			>
				<img
					src="/assets/five_mins.png"
					alt="A five-minute call can tell you if you are entitled to compensation."
				/>
				<br />
				{t("WHY_AM_I_SEEING_THIS_")}
			</div>
			<div className="right">
				<h3 style={{ color: "var(--orange)" }}>
					{t("IMPORTANT_MESSAGE_ABOUT_YOUR_ACCIDENT")}.
				</h3>
				<br />
				<p style={{ fontSize: "1.1em" }}>
					<Trans i18nKey={"IF_YOU_OR_AN_OCCUPANT"} />
				</p>
				<img
					src="/assets/five_mins.png"
					alt="A five-minute call can tell you if you are entitled to compensation."
					className="hide-desktop"
					style={{ float: "right", width: 80, margin: "0 20px" }}
				/>
				<br />
				<br />
				<h3>{t("INJURED__WOULD_YOU_LIKE_A_FREE_CASE_EVALUATION_")}</h3>
				<div>
					<br />
					{t("THE_CALLS_ARE_FREE")}
				</div>

				<div
					className="row"
					style={{
						alignItems: "flex-start",
						marginTop: 40,
						marginBottom: 20
					}}
				>
					<div>
						<div className="row" style={{ alignItems: "center" }}>
							<img
								src="/assets/callme.png"
								style={{ width: 100 }}
								className="hide-mobile"
							/>
							{findMy ? (
								<div>
									<h3 style={{ opacity: 0.7, fontSize: 21 }}>
										{t("REQUEST_A_FREE_LEGAL_CONSULTATION")}.
									</h3>
									<small className="hide-mobile">
										<Trans i18nKey={"BY_CLICKING_YES"} />
									</small>
								</div>
							) : (
								<div>
									<h3 style={{ opacity: 0.7 }}>
										{t("REQUEST_A_CALL_ABOUT_MY_ACCIDENT")}.
									</h3>
									<small className="hide-mobile">
										<Trans i18nKey={"BY_CLICKING_YES"} />
									</small>
								</div>
							)}
						</div>
					</div>
					<div style={{ flex: 1 }}>
						<div className="row yes-no">
							<img
								src="/assets/callme.png"
								style={{
									width: 88,
									position: "relative",
									display: "inline",
									top: 10
								}}
								className="hide-desktop"
							/>
							<div
								onClick={() => {
									setLegalConsult(false);
									setConfirm(true);
								}}
								className={legalConsult === false ? "checked" : ""}
								data-testid="no"
							>
								{t("NO")}
								<div className="yes-no-box">
									<img src="/assets/checked.svg" />
								</div>
							</div>
							<div
								onClick={() => {
									setLegalConsult(true);
									setConfirm(true);
								}}
								className={legalConsult ? "checked" : ""}
								data-testid="yes"
							>
								{t("YES")}
								<div className="yes-no-box">
									<img src="/assets/checked.svg" />
								</div>
							</div>
							{/* <div
								style={{ minWidth: 120, paddingTop: 25 }}
								onClick={() => {
									setLegalConsult(false);
									setConfirm(true);
									next();
								}}
								data-testid="no"
							>
								<a>No, Thanks</a>
							</div> */}
						</div>
						<small
							className="hide-desktop"
							style={{ width: 180, display: "block", margin: "20px auto 0" }}
						>
							<Trans i18nKey={"BY_CLICKING_YES"} />
						</small>
					</div>
				</div>
				{findMy && legalConsult ? (
					<div>
						<br />
						<h3>
							<Trans i18nKey={"BEFORE_WE"} />
						</h3>
						<br />
						<div
							className="row yes-no"
							style={{
								marginTop: 10,
								marginBottom: 10,
								alignItems: "center"
							}}
						>
							<div
								style={{ textAlign: "right" }}
								onClick={() => {
									setHasLawyer(hasLawyer === undefined ? false : !hasLawyer);
								}}
							>
								{t("I_AM_NOT_CURRENTLY_REPRESENTED_BY_A_LAWYER")}
							</div>
							<div
								onClick={() => {
									setHasLawyer(hasLawyer === undefined ? false : !hasLawyer);
								}}
								className={hasLawyer === false ? "checked" : ""}
								data-testid="hasLawyer"
								style={{ maxWidth: 125 }}
							>
								<div className="yes-no-box" style={{ marginLeft: 0 }}>
									<img src="/assets/checked.svg" />
								</div>
							</div>
						</div>
						<div
							className="row yes-no"
							style={{
								marginTop: 10,
								marginBottom: 10,
								alignItems: "center"
							}}
						>
							<div
								style={{ textAlign: "right" }}
								onClick={() => {
									setInjured(!injured);
								}}
							>
								{t("THERE_WERE_INJURIES_INVOLVED_IN_THIS_ACCIDENT")}
							</div>
							<div
								onClick={() => {
									setInjured(!injured);
								}}
								className={injured ? "checked" : ""}
								data-testid="injured"
								style={{ maxWidth: 125 }}
							>
								<div className="yes-no-box" style={{ marginLeft: 0 }}>
									<img src="/assets/checked.svg" />
								</div>
							</div>
						</div>
						<br />
						<br />
					</div>
				) : null}
			</div>
			<div style={{ clear: "both" }} />
			{legalConsult === undefined ? (
				<small>
					{isSecondaryTcpa ? (
						
						<Trans
							i18nKey={"BY_SUBMITTING_THIS_REQUEST_SECONDARY"}
							components={{
								link: <LinkToPartnerAttorneys text={t("MARKETING_PARTNERS")} />
							}}
						/>
					) : (
						t("BY_SUBMITTING_THIS_REQUEST")
					)}{" "}
					<a href="https://myaccident.org/terms-of-use" target="_blank">
						{t("TERMS_OF_USE")}
					</a>
				</small>
			) : null}
			{legalConsult === true ? (
				<small>
					<TcpaOnYesLegalConsult tcpaText={tcpaText} isSecondaryTcpa={isSecondaryTcpa} />
				</small>
			) : null}
			{legalConsult === false ? (
				<small>
					{isSecondaryTcpa ? (
						<Trans
							i18nKey={"KEEPING_MYACCIDENT_ORG_FREE_SECONDARY"}
							components={{
								link: <LinkToPartnerAttorneys text={t("SPONSORS")} />
							}}
						/>
					) : (
						<Trans i18nKey={"KEEPING_MYACCIDENT_ORG_FREE"} />
					)}{" "}
					<a href="https://myaccident.org/terms-of-use" target="_blank">
						{t("TERMS_OF_USE")}.
					</a>
					.
				</small>
			) : null}
		</div>
	);
}

export function LinkToPartnerAttorneys({ text }) {
	return (
		<a href="https://myaccident.org/partner-attorneys" target="_blank">
			{text}
		</a>
	);
}

